import * as React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Wave, { WaveType } from "../components/ui/wave"
import { PageType } from "./about.en"
import TopSection from "../components/consulting/TopSection"
import { BlogPostType } from "../components/blog/blogPostSection"
import Body from "../components/consulting/Body"
import { Locale } from "../common/appContext"

const ConsultantPage = ({ path }) => {
  const { graphCmsPage, graphCmsBlogPost } = useStaticQuery(graphql`
    {
      graphCmsPage(id: { eq: "Page:ckz14w5xkrex30c58o1615ask:sv:PUBLISHED" }) {
        heading
        body {
          html
        }
        seo {
          metaTitle
          metaDescription
          ogImage {
            url
          }
          focusKeywords
        }
      }
      graphCmsBlogPost(
        blogCategories: {
          elemMatch: { category: { eq: "howItWorks_app_consulting" } }
        }
        locale: { eq: sv }
      ) {
        heading
        body {
          html
        }
      }
    }
  `)

  const data: PageType = graphCmsPage
  const post: BlogPostType = graphCmsBlogPost

  return (
    <Layout
      initialLogoWhite
      initialTextColorThemeWhite
      locale={Locale.sv}
      path={path}
    >
      <SEO title="Konsult | cruto.io" meta={data?.seo} />
      <div>
        <TopSection {...data} />
      </div>

      <Body heading={post.heading} body={post.body} />
      <Wave className={"u-c-orange"} type={WaveType.v5} />
    </Layout>
  )
}

export default ConsultantPage
